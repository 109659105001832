<template>
  <!-- 培训报名 -->
  <div class="trainingRegister">
    <van-nav-bar title="请选择要报名的课程"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />
    <van-search v-model="value"
                shape="round"
                placeholder="课程/讲师"
                @search="onSearch" />
    <div class="overflow_div">
      <van-pull-refresh v-model="isLoading"
                        @refresh="onRefresh">
        <van-list v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad">
          <van-cell v-for="item in list"
                    :key="item"
                    class="examList"
                    center
                    @click="toRegister">
            <img src="@/assets/img/trainingRegister.png"
                 alt="" />
            <div class="info">
              <div class="head">
                <span class="courseName"> 标准固化模型 </span>
              </div>
              <span class="lecturerName">讲师：元翰必</span>
              <div class="middle">
                <span> 已报 </span>
                <span class="number">10</span>
                <span>人</span>
              </div>
              <div class="footer">
                <span class="date">
                  2021-05-20
                </span>
                <div class="right"><span class="timeSlot">12:32——13:32</span></div>
              </div>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>

</template>

<script>
import { Toast } from 'vant'
export default {
  data () {
    return {
      value: '',
      isLoading: false,
      list: [],
      loading: false,
      finished: false,
    }
  },
  methods: {
    onClickLeft () {
      this.$router.push('/home')
    },
    onClickRight () { },
    onLoad () {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++)
        {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 20)
        {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh () {
      setTimeout(() => {
        Toast('刷新成功');
        this.isLoading = false;
      }, 1000);
    },
    toRegister () {
      this.$router.push('/signUpDetails')
    },
    onSearch () {

    }
  }
}
</script>

<style lang="less" scoped>
.trainingRegister {
  height: 100vh;
  overflow: hidden;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    overflow: auto;
    height: calc(100% - 206px);
    .examList {
      padding: 32px;
      .van-cell__value {
        display: flex;
        img {
          width: 306px;
          height: 190px;
          margin-right: 18px;
        }
        .info {
          flex: 1;
          .head {
            .courseName {
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
          }
          .lecturerName {
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #666666;
          }
          .middle {
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #999999;
            .number {
              color: #2b8df0;
            }
          }
          .footer {
            display: flex;
            justify-content: space-between;
            // align-items: flex-end;
            align-items: baseline;
            .date {
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #999999;
            }
            .right {
              .timeSlot {
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #999999;
              }
            }
          }
        }
      }
    }
  }
}
</style>